<template>
  <div class="flex flex-row items-end justify-end gap-4">
    <div
      class="mr-4 flex w-auto flex-shrink flex-row items-center gap-2 rounded border-2 border-blue-dark bg-white px-2 py-1 text-[1rem] leading-tight text-blue-dark tablet:mr-0"
    >
      <nuxt-icon name="verified" class="text-[2.25em] [&>svg]:!mb-0" filled />
      Wir sind Teil vom
      <br />Netzwerk Hörgesundheit
    </div>
  </div>
</template>
